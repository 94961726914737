<template>
    <div>
        <stageList :game_id="game_id"></stageList>
    </div>
</template>

<script>

import stageList from '@/components/games/stage/list.vue';

export default {
    components: {
        stageList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 6,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>