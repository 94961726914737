import http from '@/utils/http';

export const StageInfo = (parameter) => {
  return http.request({
    url: '/v1/games/stage/info',
    method: 'get',
    params: parameter
  })
}

export const StageSearch = (parameter) => {
  return http.request({
    url: '/v1/games/stage/search',
    method: 'get',
    params: parameter
  })
}

export const GetStage = (parameter) => {
  return http.request({
    url: '/v1/games/stage/list',
    method: 'get',
    params: parameter
  })
}

export const AddStage = (parameter) => {
  return http.request({
    url: '/v1/games/stage/add',
    method: 'post',
    data: parameter
  })
}

export const DelStage = (parameter) => {
  return http.request({
    url: '/v1/games/stage/del',
    method: 'post',
    data: parameter
  })
}

export const UpdateStage = (parameter) => {
  return http.request({
    url: '/v1/games/stage/update',
    method: 'post',
    data: parameter
  })
}
